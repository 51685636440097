import React, { useRef, useEffect, useState, Fragment } from "react";
import Editor from "@monaco-editor/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";

const MonacoEditor = (props) => {
  const { dataIn } = props;
  const [displayNames, setDisplayName] = useState(true);
  const [copySuccess, setCopySuccess] = useState(true);
  const [dummyCode, setValue] = useState("");
  const containerRef = useRef();

  const handleChange = (e) => {
    dataIn.map((res) => {
      if (res.value == e.target.value) {
        setValue(res.data);
        setDisplayName(res.title);
      }
    });
  };

  const copyToClipBoard = async (copyMe) => {
    await navigator.clipboard.writeText(copyMe);
    setCopySuccess(false);
    setTimeout(() => {
      setCopySuccess(true);
    }, 1000);
  };

  useEffect(() => {
    setValue(dataIn[0]["data"]);
    setDisplayName(dataIn[0].title);
  }, []);

  return (
    <Fragment>
      <div className="editor-title">
        {displayNames}
        <select
          data-testid="custom-element"
          style={{ border: "none" }}
          onChange={(e) => handleChange(e)}
        >
          {dataIn.map((value) => (
            <option key={value.value} value={value.value}>
              {value.name}
            </option>
          ))}
        </select>
      </div>

      <Editor
        height="24vh"
        language={"javascript"}
        theme={`vs-dark`}
        options={{ readOnly: props.editData || true }}
        value={dummyCode}
        _ref={containerRef}
      />
      <button type="button" onClick={() => copyToClipBoard(dummyCode)}>
        <FontAwesomeIcon
          style={{ color: "black" }}
          icon={copySuccess ? faCopy : faCheck}
          data-testid="btn-copy"
        />
      </button>
    </Fragment>
  );
};

export default MonacoEditor;
