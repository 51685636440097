import React from "react";
import Transfers from "../components/tranfers";
import Sesion from "../components/commons/sesion";
import PermissionsGate from "../components/commons/PermissionsGate";

export default function Transfer() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Transfer"} />
      <PermissionsGate
        scopes={"resources.transfers.actions.view"}
        foundRedirect={true}
      >
        <Transfers />
      </PermissionsGate>
    </div>
  );
}
