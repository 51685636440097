import { MenuItem, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../../components/commons/alert";
import { formatYMD } from "../../utils/validateExpression";
import { FilterButton } from "../commons/filterButton";
import { CleanFilterButton } from "../commons/cleanFilterButton";
import { getClientId } from "../utils/jwt";
import useAuth from "../../hooks/useAuth";

export function FilterDetails({
  data,
  dataFilter,
  dateDefaultFilter,
  setInputSearch,
}) {
  const [t] = useTranslation("global");
  const [dateInit, setDateInit] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [branch, setBranch] = useState("");
  const [branchSelect, setBranchSelect] = useState([]);
  const [status, setStatus] = useState("");
  const [statuSelect, setStatusSelect] = useState([]);
  const [payment, setPayment] = useState("");
  const [paymentSelect, setPaymentSelect] = useState([]);
  const [initFiltersDate, setInitFiltersDate] = useState(true);
  const { auth } = useAuth();

  const filterData = () => {
    setInputSearch("");
    setInitFiltersDate(false);
    if (dateEnd === "" && dateInit === "") {
      let parameters = {
        status: status,
        branch: branch,
        payment: payment,
        date_init: dateInit,
        date_end: dateEnd,
        client_id: getClientId(auth),
      };

      dataFilter(parameters);
    } else if (dateEnd === "" || dateInit === "") {
      return Alert(t("Filter.Message.NotDates"), "error");
    } else if (dateEnd !== "" || dateInit !== "") {
      if (dateEnd >= dateInit) {
        let parameters = {
          status: status,
          branch: branch,
          payment: payment,
          date_init: dateInit,
          date_end: dateEnd,
          client_id: getClientId(auth),
        };
        dataFilter(parameters);
      } else {
        return Alert(t("Filter.Message.DateIncorrect"), "error");
      }
    }
  };

  const cleanFilter = () => {
    setInputSearch("");
    setInitFiltersDate(true);
    setDateInit("");
    setDateEnd("");
    setBranch("");
    setBranchSelect([]);
    setStatus("");
    setStatusSelect([]);
    setPayment("");
    setPaymentSelect([]);
    let parameters = {
      params: {
        status: "",
        branch: "",
        payment: "",
        dateInit: "",
        dateEnd: "",
        client_id: getClientId(auth),
      },
    };

    dataFilter(parameters);
  };

  useEffect(() => {
    let branchList = [];
    let statusList = [];
    let paymentList = [];
    if (initFiltersDate) {
      setDateInit(formatYMD(dateDefaultFilter.dateInit));
      setDateEnd(formatYMD(dateDefaultFilter.dateEnd));
    }
    data.forEach((obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (
          key === "store_name" &&
          !branchList.find((element) => element === value)
        ) {
          branchList.push(value);
        }
        if (
          key === "method_payment" &&
          !paymentList.find((element) => element === value)
        ) {
          paymentList.push(value);
        }
        if (
          key === "status" &&
          !statusList.find((element) => element === value)
        ) {
          statusList.push(value);
        }
      });
    });
    setStatusSelect(statusList);
    setBranchSelect(branchList);
    setPaymentSelect(paymentList);
  }, [data, dateDefaultFilter, initFiltersDate]);

  const menuItem = (value) => {
    return (
      <MenuItem key={value} value={value}>
        {value}
      </MenuItem>
    );
  };

  return (
    <Stack direction="row" width="100%" spacing={1} justifyContent="start">
      <Stack
        direction="row"
        width="550vh"
        spacing={1}
        justifyContent="start"
        sx={{ marginInlineStart: 2 }}
      >
        <TextField
          id="dateInit"
          data-testid="dateInit"
          label={t("Filter.DateInit")}
          type="date"
          fullWidth
          size="small"
          onChange={(event) => {
            setDateInit(event.target.value);
          }}
          sx={{ background: "white", width: "155px" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={dateInit}
        />
        <TextField
          id="dateEnd"
          data-testid="dateEnd"
          label={t("Filter.DateEnd")}
          type="date"
          fullWidth
          size="small"
          onChange={(event) => {
            setDateEnd(event.target.value);
          }}
          sx={{ background: "white", width: "155px" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={dateEnd}
        />
        <TextField
          id="outlined-branch"
          label={t("Filter.Branch")}
          select
          value={branch}
          onChange={(event) => {
            setBranch(event.target.value);
          }}
          sx={{ background: "white", width: "180px" }}
          fullWidth
          size="small"
          inputProps={{ id: "select", "data-testid": "select" }}
          InputLabelProps={{ style: { color: "#00377D" } }}
        >
          {branchSelect.map((value) => menuItem(value))}
        </TextField>
        <TextField
          id="outlined-status"
          label={t("Filter.Status")}
          select
          value={status}
          size="small"
          inputProps={{ "data-testid": "selectStatus" }}
          onChange={(event) => {
            setStatus(event.target.value);
          }}
          sx={{ background: "white", width: "160px" }}
          fullWidth
          InputLabelProps={{ style: { color: "#00377D" } }}
        >
          {statuSelect.map((value) => (
            <MenuItem key={value} value={value}>
              {t("Filter.StatusList." + value)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-payment"
          label={t("Filter.Payment")}
          value={payment}
          size="small"
          inputProps={{ "data-testid": "selectPayment" }}
          onChange={(event) => {
            setPayment(event.target.value);
          }}
          select
          sx={{ background: "white", width: "160px" }}
          fullWidth
          InputLabelProps={{ style: { color: "#00377D" } }}
        >
          {paymentSelect.map((value) => menuItem(value))}
        </TextField>
      </Stack>
      <Stack direction="row" width="100%" spacing={1} justifyContent="end">
        <CleanFilterButton filterFnc={cleanFilter} label={t("Filter.Clean")} />
        <FilterButton filterFnc={filterData} label={t("Filter.Filter")} />
      </Stack>
    </Stack>
  );
}
