import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import BasicTable from "../commons/basicTable";
import AddTransfer from "./addTransfer";
import Button from "@mui/material/Button";
import { currencyFormatter } from "../../utils/validateExpression";
import PermissionsGate from "../commons/PermissionsGate";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { getClientId } from "../utils/jwt";
import { useNavigate, useLocation } from "react-router-dom";
import GenericBackdrop from "../commons/GenericBackdrop";

const Transfers = () => {
  const { auth } = useAuth();
  const [transfer, settransfer] = useState([]);
  const [createTransfer, setCreateTransfer] = useState(false);
  const [t] = useTranslation("global");
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const columns = [
    "Transfer.Date",
    "Transfer.Time",
    "Transfer.Amount",
    "Transfer.Bank",
    "Transfer.BankAccount",
    "Transfer.Status",
  ];

  useEffect(() => {
    setLoader(true);
    let isMounted = true;
    const controller = new AbortController();

    const getTransfer = async () => {
      try {
        const response = await axiosPrivate.get(
          `withdrawals/${getClientId(auth)}`,
          {
            signal: controller.signal,
          }
        );
        if (isMounted) {
          let newjson = [];
          for (const x in response.data.results) {
            const json = {
              date: response.data.results[x].date,
              time: response.data.results[x].time,
              amount: currencyFormatter(
                response.data.results[x].amount,
                "es-HN",
                "HNL"
              ),
              bank: response.data.results[x].bank,
              bankAccount: cardHide(response.data.results[x].bankAccount),
              status: messagestatus(response.data.results[x].status),
            };
            newjson.push(json);
          }
          settransfer(newjson);
        }
        setLoader(false);
      } catch (err) {
        console.error(err);
        setLoader(false);
        navigate("/SignIn", { state: { from: location }, replace: true });
      }
    };

    getTransfer();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const cardHide = (card) => {
    let hideNum = [];
    for (let i = 0; i < card.length; i++) {
      if (i < card.length - 4) {
        hideNum.push("*");
      } else {
        hideNum.push(card[i]);
      }
    }
    return hideNum.join("");
  };

  const messagestatus = (status) => {
    if (status === "process") {
      return t("Transfer.StatusMessage.Process");
    } else if (status === "rejected") {
      return t("Transfer.StatusMessage.Rejected");
    } else if (status === "complete") {
      return t("Transfer.StatusMessage.Complete");
    } else {
      return t("Transfer.StatusMessage.Created");
    }
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      {createTransfer ? (
        <AddTransfer showCreateTransfer={setCreateTransfer} />
      ) : (
        <>
          <PermissionsGate scopes={"resources.transfers.actions.create"}>
            <div style={{ textAlign: "right", paddingBottom: "20px" }}>
              <Button
                onClick={() => setCreateTransfer(true)}
                style={{
                  background: "#FFC10E",
                  textTransform: "none",
                  width: "209px",
                  color: "#404A56",
                  borderRadius: "8px",
                }}
                className="action-button-secondary"
              >
                {t("Transfer.Scheduletransfer")}
              </Button>
            </div>
          </PermissionsGate>
          <BasicTable dataTable={transfer} dataColumns={columns} />
        </>
      )}
    </Fragment>
  );
};

export default Transfers;
