import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from "react-i18next";

import PermissionsGate from "../commons/PermissionsGate";
import { Confirm } from "../commons/alert";

const ActionDeleteUser = ({ row, handleDeleteUser }) => {
  const [t] = useTranslation("global");

  return (
    <PermissionsGate scopes={"resources.configuration.users.actions.delete"}>
      <div
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          color: "#404A56",
        }}
        data-testid={`Delete_${row.user_id}`}
        onClick={async () => {
          let isConfirmed = await Confirm(
            "",
            t("Users.Delete.Message", { user: row.name }),
            "warning"
          );
          if (isConfirmed) {
            handleDeleteUser(row.user_id);
          }
        }}
      >
        {<DeleteForeverIcon style={{ cursor: "hand" }} />}
      </div>
    </PermissionsGate>
  );
};

export default ActionDeleteUser;
