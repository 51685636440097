import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();
  return () => {
    setAuth({});
    localStorage.clear();
  };
};

export default useLogout;
