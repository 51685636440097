import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  subtitlesBox: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      paddingBottom: theme.spacing(1),
    },
  },
  subtitleBox: {
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(0.1),
    },
  },
  root: {
    fontSize: 14,
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 1),
    },
  },
  description: {
    color: "#404A56",
    fontWeight: 400,
  },
  name: {
    color: "green",
    fontWeight: 900,
  },
}));

export default function TransactionCard({ information }) {
  const classes = useStyles();

  const styleBorder = {
    height: "10%",
    border: "1px solid",
    borderColor: "#DFE3E7",
  };

  const getSubTitleStyle = (value) => {
    switch (value) {
      case "name":
        return {
          color: "#404A56",
          fontWeight: 600,
        };
      case "type":
        return {
          color: "#708195",
          fontWeight: 400,
        };
      case "required":
        return {
          color: "#B00020",
          fontWeight: 400,
        };
      default:
        return {};
    }
  };

  const boxTags = (tag, styleClass, style = {}) => {
    return (
      <Box className={styleClass} sx={style}>
        <label>{tag}</label>
      </Box>
    );
  };

  const buildSubtitle = (data) => {
    return (
      <>
        {Object.keys(data).map((cell) => {
          const style = getSubTitleStyle(cell);
          data[cell] = cell == "required" && data[cell] ? cell : data[cell];
          if (cell == "required" && !data[cell]) {
            delete data[cell];
          }

          return (
            cell != "description" &&
            boxTags(data[cell], classes.subtitleBox, style)
          );
        })}
      </>
    );
  };

  return (
    <>
      {information.map((data, i) => (
        <>
          <Box className={classes.root}>
            <Box className={classes.subtitlesBox}>{buildSubtitle(data)}</Box>
            {boxTags(data.description, classes.description)}
          </Box>
          {i + 1 <= information.length - 1 && <Box sx={styleBorder}></Box>}
        </>
      ))}
    </>
  );
}
