import useAuth from "../../hooks/useAuth";

function CheckPermission(scopes) {
  const { auth } = useAuth();
  let permissionGranted =
    auth?.roles && scopes.split(".").reduce((obj, i) => obj[i], auth?.roles);
  return permissionGranted;
}

export default CheckPermission;
