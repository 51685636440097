import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { Alert } from "../commons/alert";
import GenericBackdrop from "../commons/GenericBackdrop";
import {
  Box,
  Button,
  Card,
  CardContent,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import AddTransferVerify from "./addTransferVerify";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { getClientId } from "../utils/jwt";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="L"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function AddTransfer({ showCreateTransfer }) {
  const [t] = useTranslation("global");

  const [verifyTransfer, setVerifyTransfer] = useState(false);
  const [datadetail, setdatadetail] = useState({});
  const [accountData, setAccountData] = useState([]);
  const [amount, setAmount] = useState({
    value: "0",
  });
  const [substr, setsubstr] = useState("");
  const [accountId, setAccountId] = useState({});
  const [loader, setLoader] = useState(false);
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  let sub;
  let dataNow;
  let date = new Date().toLocaleString();

  useEffect(() => {
    setLoader(true);
    let isMounted = true;
    const controller = new AbortController();

    const getBankAccounts = async () => {
      try {
        const response = await axiosPrivate.get(
          `withdrawals/bank-accounts`,
          { params: { client_id: getClientId(auth) } },
          {
            signal: controller.signal,
          }
        );
        if (isMounted) {
          setAccountData(response.data.results);
          setsubstr(formatAccount(response.data.results));
        }
        setLoader(false);
      } catch (err) {
        console.error(err);
        setLoader(false);
        showCreateTransfer(false);
        Alert(t("Transfer.NecessaryBankAccount"), "warning");
      }
    };

    getBankAccounts();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  function inputData() {
    dataNow = {
      Bank: accountData[0].bankName,
      BankAccount: accountData[0].account,
      Date: date,
      Amount: amount.value,
    };
    return dataNow;
  }

  function formatAccount(row) {
    sub = row[0].bankName + " - " + row[0].account;
    return sub;
  }

  const handdlerAmount = (event) => {
    setAmount({
      value: event.target.value,
      [event.target.name]: event.target.value,
    });
  };

  const showVerifyTransfer = (data, active) => {
    if (amount.value > 0) {
      setVerifyTransfer(active);
      setdatadetail(data);
      setAccountId(accountData[0].account_id);
    }
  };

  return (
    <div>
      {verifyTransfer ? (
        <AddTransferVerify
          data={datadetail}
          showVerifyTransfer={showVerifyTransfer}
          showCreateTransfer={showCreateTransfer}
          idAccount={accountId}
        />
      ) : (
        <Fragment>
          <GenericBackdrop open={loader} />
          <Card style={{ borderRadius: "16px" }}>
            <div style={{ width: "330px" }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  marginLeft: "24px",
                  paddingTop: "32px",
                  fontWeight: 700,
                  fontSize: "18px",
                  color: "#404A56",
                }}
                className="action-button"
              >
                {t("Transfer.TransferDetail")}
              </Typography>
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                  }}
                >
                  <div>
                    <TextField
                      id="outlined-basic"
                      label={t("Transfer.Bank_Account")}
                      value={substr}
                      disabled
                      size="small"
                      fullWidth
                      InputLabelProps={{ style: { color: "#00377D" } }}
                    />
                    <InputLabel
                      htmlFor="formatted-numberformat-input"
                      sx={{ marginLeft: "10px" }}
                    ></InputLabel>
                    <TextField
                      id="formatted-numberformat-input"
                      size="small"
                      fullWidth
                      name="numberformat"
                      InputLabelProps={{ style: { color: "#00377D" } }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                      label={t("Transfer.TransferMsgWithdraw")}
                      value={amount.value}
                      onChange={handdlerAmount}
                      sx={{ marginLeft: "10px" }}
                    />
                  </div>
                </Box>
                <div
                  style={{
                    marginLeft: "30px",
                    marginTop: "20px",
                    textAlign: "right",
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    component="div"
                    sx={{
                      marginLeft: "20px",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#404A56",
                    }}
                  >
                    L3,000,000.00
                  </Typography>
                  <Typography
                    color={"Gray"}
                    variant="h6"
                    gutterBottom
                    component="div"
                    sx={{
                      marginLeft: "20px",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#708195",
                    }}
                  >
                    {t("Transfer.TransferMsgAvalible")}
                  </Typography>
                </div>
              </CardContent>
            </div>
          </Card>
          <div
            style={{
              float: "right",
              marginBlockEnd: "30px",
              marginTop: "30px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                background: "white",
                color: "#404A56",
                textTransform: "none",
                minWidth: "180px",
                borderColor: "#FFC10E",
                height: "48px",
              }}
              onClick={() => showCreateTransfer(false)}
              className="action-button-outlined"
            >
              {t("Buttons.Cancel")}
            </Button>

            <Button
              style={{
                background: "#FFC10E",
                color: "#404A56",
                marginLeft: "10px",
                textTransform: "none",
                minWidth: "180px",
                height: "48px",
              }}
              onClick={() => showVerifyTransfer(inputData(), true)}
              className="action-button"
            >
              {t("Buttons.Continue")}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
}
