import { Routes, Route, useLocation } from "react-router-dom";
import SideBar from "./views/sideBar";
import Layout from "./components/Layout";
import Configuration from "./views/Configuration";
import Help from "./views/Help";
import Transfer from "./views/Transfer";
import Insight from "./views/Insight";
import Payments from "./views/Payments";
import MessageScreen from "./views/MessageScreen";
import Contract from "./views/Contract";
import FormKym from "./views/FormKym";
import ResetPassword from "./views/ResetPassword";
import SignUp from "./views/SignUp";
import SignIn from "./views/SignIn";
import ForgotPassword from "./views/ForgotPassword";
import PaymentIntegration from "./views/PaymentIntegration";
import TransfersDetail from "./views/TransfersDetail";
import Unauthorized from "./components/Unauthorized";
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import TagManager from "react-gtm-module";
import "./App.css";
import useAuth from "./hooks/useAuth";
import Privacy from "./views/Privacy";

const ROLES = {
  Read: "Read",
  Owner: "Owner",
  Standard: "Standard",
  DevUser: "Dev User",
  Viewer: "Viewer",
  Admin: "Admin",
  Legacy: "Legacy",
};

function App() {
  const location = useLocation();
  const { auth } = useAuth();

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    dataLayer: {
      merchant_name: auth.businessName,
      merchant_id: auth.userId,
      event: location.pathname,
    },
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <>
      <SideBar />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="SignIn" element={<SignIn />} />
          <Route path="SignUp" element={<SignUp />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="Contract" element={<Contract />} />
          <Route path="Form" element={<FormKym />} />
          <Route path="Privacy" element={<Privacy />} />
          <Route path="MessageScreen" element={<MessageScreen />} />

          <Route element={<PersistLogin />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Read,
                    ROLES.Owner,
                    ROLES.Standard,
                    ROLES.DevUser,
                    ROLES.Viewer,
                    ROLES.Admin,
                    ROLES.Legacy,
                  ]}
                />
              }
            >
              <Route path="/" element={<Insight />} />
              <Route path="ResetPassword" element={<ResetPassword />} />
              <Route path="Transfer" element={<Transfer />} />
              <Route path="Help" element={<Help />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Owner, ROLES.Standard, ROLES.Admin]}
                />
              }
            >
              <Route path="Configuration" element={<Configuration />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Read,
                    ROLES.Owner,
                    ROLES.Standard,
                    ROLES.DevUser,
                    ROLES.Viewer,
                    ROLES.Admin,
                  ]}
                />
              }
            >
              <Route path="TransfersDetail" element={<TransfersDetail />} />
              <Route path="Payments" element={<Payments />} />
              <Route
                path="PaymentIntegration"
                element={<PaymentIntegration />}
              />
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
