import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  let RefreshToken = localStorage.getItem("RefreshToken");

  return async () => {
    axios.defaults.headers.common["mts-token"] = RefreshToken;
    const response = await axios.get("/token/refresh");
    setAuth({
      country: response.data.country,
      name: response.data.fullName,
      roles: response.data.roles,
      authToken: response.data.authToken,
      email: response.data.email,
      roleName: [response.data.roleName],
    });
    return response.data.accessToken;
  };
};

export default useRefreshToken;
