import { React, useEffect, useState, Fragment } from "react";
import dataJson from "./config/reset-password.json";
import GenericForm from "./genericForm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { schema, getYup } from "./utils/yup";
import { useNavigate, useLocation } from "react-router-dom";
import { SnackBar } from "./commons/alert";
import { useTranslation } from "react-i18next";
import GenericBackdrop from "./commons/GenericBackdrop";

export default function FormResetPassword() {
  const [t] = useTranslation("global");
  const [data, setData] = useState(dataJson);
  const [validates, setValidates] = useState({});
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const email = location.state.email;

  const handleSubmit = (values) => {
    let newData = { ...data };

    let dataRequest = {
      old_password: values.old_password,
      password: values.password,
      email: email,
    };

    if (newData.forms.createdPassword === "false") {
      setLoader(true);
      axiosPrivate
        .put(`users/password`, dataRequest)
        .then((_response) => {
          setLoader(false);
          newData.forms.title = "ForgotPassword.Step2.TitleSuccess";
          newData.forms.createdPassword = "true";
          newData.forms.fields[0].props.legend =
            "ForgotPassword.Step2.FieldPassword.Props.Legend";
          newData.forms.fields[0].props.type = "";
          newData.forms.fields[1].props.type = "";
          newData.forms.fields[2].props.type = "";
          newData.forms.images[0].props.type = "image";
          newData.forms.actionsButtons[0].label =
            "ForgotPassword.Step2.ActionsButtons.Label2";

          setData(newData);
        })
        .catch((_err) => {
          setLoader(false);
          SnackBar(t("General.Message.ErrorService"), "error");
        });
    } else {
      newData.forms.title = "ForgotPassword.Step2.Title";
      newData.forms.createdPassword = "false";
      newData.forms.fields[0].props.legend = "";
      newData.forms.fields[0].props.type = "password";
      newData.forms.fields[1].props.type = "password";
      newData.forms.fields[2].props.type = "password";
      newData.forms.images[0].props.type = "";
      newData.forms.actionsButtons[0].label =
        "ForgotPassword.Step2.ActionsButtons.Label";
      setData(newData);
      navigate("/SignIn");
    }
  };

  useEffect(() => {
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <GenericForm
        form={dataJson.forms}
        initialValue={{}}
        validates={getYup(validates)}
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
}
