import { React, useEffect, useState } from "react";
import Data from "../config/integrations-options.json";
import Cards from "../commons/cards";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import CheckPermission from "../utils/checkPermission";

const Integrations = (props) => {
  const [t] = useTranslation("global");
  const [dataOpts, setDataOpts] = useState([]);
  const { auth } = useAuth();

  useEffect(() => {
    Data.options.forEach((element) => {
      let permissionGranted =
        auth?.roles &&
        element.title.split(".").reduce((obj, i) => obj[i], auth?.roles);
      if (permissionGranted.actions.view) {
        setDataOpts((old) => [...old, element]);
      }
    });
  }, []);

  return (
    <div className="generic-card">
      <Typography
        color={"#404A56"}
        mb={6}
        style={{ fontSize: "16px", fontWeight: 700, textAlign: "justify" }}
        gutterBottom
        component="div"
      >
        {t("Integrations.Title")}
      </Typography>
      <Cards
        data={dataOpts}
        onClick={props.onClick}
        permissionButton={CheckPermission(
          "resources.configuration.integrations.actions.viewImplementacion"
        )}
      />
    </div>
  );
};

export default Integrations;
