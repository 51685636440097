import React, { useState, useEffect } from "react";
import dataJson from "./config/signUp-verify.json";
import GenericForm from "./genericForm";
import { getYup } from "./utils/yup";

export default function VerifyEmail(_props, email) {
  const [validates, setValidates] = useState({});

  const handleSubmit = (values) => {
    _props.onBefore(values);
  };

  useEffect(() => {
    dataJson.forms["email"] = _props.email;
    setValidates({});
  }, []);

  return (
    <GenericForm
      form={dataJson.forms}
      initialValue={{}}
      validates={getYup(validates)}
      onSubmit={handleSubmit}
    />
  );
}
