import React from "react";
import Sesion from "../components/commons/sesion";
import QrGenerate from "../components/qr/qrGenerate";
import PermissionsGate from "../components/commons/PermissionsGate";

export default function Payments() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Payments"} />
      <PermissionsGate
        scopes={"resources.payments.actions.view"}
        foundRedirect={true}
      >
        <QrGenerate
          merchant="Costa del Este-Café Espresso"
          idMerchant="345GDK13"
        ></QrGenerate>
      </PermissionsGate>
    </div>
  );
}
