import React from "react";
import { ExportExcel } from "../../utils/exportExcel";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { SendEmail } from "../../utils/SendEmail";
import Card from "@mui/material/Card";
import { FilterDetails } from "./filterDetails";
import Search from "../commons/search";
import PermissionsGate from "../commons/PermissionsGate";

export default function Buttons({
  data,
  columns,
  backUp,
  setSearch,
  columnsSearch,
  setIsSearch,
  dataFilter,
  dateDefaultFilter,
  setInputSearch,
  inputSearch,
  filterEmail,
}) {
  const [t] = useTranslation("global");
  return (
    <Stack mb={2} mt={2}>
      <Typography variant="subTitle18">
        {t("Insights.Message.Movements")}
      </Typography>
      <Stack direction="row" width="100%" mt={2}>
        <PermissionsGate scopes={"resources.insights.actions.viewFilter"}>
          <Stack
            direction="row"
            width="150vh"
            spacing={1}
            justifyContent="start"
          >
            <Search
              backUp={backUp}
              setSearch={setSearch}
              columnsSearch={columnsSearch}
              setIsSearch={setIsSearch}
              setInputSearch={setInputSearch}
              inputSearch={inputSearch}
            />
          </Stack>
        </PermissionsGate>
        <PermissionsGate scopes={"resources.insights.actions.exportData"}>
          <Stack direction="row" width="150vh" spacing={1} justifyContent="end">
            <SendEmail fileData={filterEmail} fileName={"Transfers"} />
            <ExportExcel
              fileType={"xlsx"}
              fileName={"Transfers"}
              data={data}
              columns={columns}
            />
            <ExportExcel
              fileType={"csv"}
              fileName={"Transfers"}
              data={data}
              columns={columns}
            />
          </Stack>
        </PermissionsGate>
      </Stack>
      <Card
        sx={{
          margin: "1vh 0",
          padding: "4vh 0",
          backgroundColor: "#EFF1F399",
        }}
      >
        <FilterDetails
          data={data}
          dataFilter={dataFilter}
          dateDefaultFilter={dateDefaultFilter}
          setInputSearch={setInputSearch}
        ></FilterDetails>
      </Card>
    </Stack>
  );
}
