import { useEffect, useState, Fragment } from "react";
import dataJson from "./config/signIn.json";
import GenericForm from "./genericForm";
import { SnackBar } from "./commons/alert";
import { schema, getYup } from "./utils/yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import { getMtsTokenId } from "./utils/jwt";
import GenericBackdrop from "../components/commons/GenericBackdrop";
import TagManager from "react-gtm-module";

const LOGIN_URL = "/login";

const Login = () => {
  const { setAuth } = useAuth();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [t] = useTranslation("global");
  const [data, setData] = useState(dataJson.forms);
  const [validates, setValidates] = useState({});
  let fromSave = localStorage.getItem("from");

  useEffect(() => {
    localStorage.setItem(
      "from",
      location.state?.from?.pathname ? location.state?.from?.pathname : "/"
    );
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  const getFront = (sign_contract, pending, mtsToke, RefreshToken) => {
    let from = fromSave;
    if (sign_contract) {
      if (mtsToke?.RESET === "1") {
        from = "/ResetPassword";
      } else {
        localStorage.setItem("RefreshToken", RefreshToken);
      }

      return from;
    }
    return `/${pending}`;
  };

  const handleSubmit = async (values) => {
    let newData = { ...data };

    try {
      setLoader(true);
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          email: values.email,
          password: values.password,
        })
      );
      let tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM,
        dataLayer: {
          merchant_name: response.data.business_name,
          merchant_id: response.data.user_id,
          event: "signIn",
        },
      };
      TagManager.initialize(tagManagerArgs);

      setAuth({
        country: response.data.country,
        name: response.data.fullName,
        roleName: [response.data.roleName],
        roles: response.data.roles,
        authToken: response.data.authToken,
        email: response.data.email,
        msisdn: response.data.msisdn,
        userId: response.data.user_id,
        businessName: response.data.business_name,
      });
      let mtsToke = getMtsTokenId(response.data.authToken.IdToken);
      let from = getFront(
        response.data.sign_contract,
        response.data.pending,
        mtsToke,
        response.data.authToken.RefreshToken
      );
      setLoader(false);
      navigate(from, {
        replace: true,
        screen: from,
        state: {
          email: values.email,
          device: mtsToke?.DEVICE_ID,
          client: mtsToke?.CLIENT_ID,
          type: response.data.data,
          userId: response.data.user_id,
        },
      });

      newData.fields[0].props.conditions[0].created = false;
      newData.fields[1].props.errCustom.err = false;
      newData.fields[1].props.legendError = "";
      setData(newData);
    } catch (err) {
      setLoader(false);
      if (!err.response) {
        SnackBar(t("General.Message.NoServerResponse"), "error");
      } else if (err.response.status === 400) {
        SnackBar(t("General.Message.MissingUsernameorPassword"), "error");
        newData.fields[0].props.conditions[0].created = false;
        newData.fields[1].props.errCustom.err = true;
        newData.fields[1].props.legendError =
          "SignIn.FieldPassword.Props.LegendError";
        setData(newData);
      } else if (err.response.status === 401) {
        SnackBar(t("General.Message.Unauthorized"), "error");
      } else {
        SnackBar(t("General.Message.LoginFailed"), "error");
      }
    }
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <GenericForm
        form={dataJson.forms}
        initialValue={{}}
        validates={getYup(validates)}
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
};

export default Login;
