import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Card,
  CardContent,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Typography,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

const Password = ({ props }) => {
  const [field, meta] = useField(props.name);
  const [t] = useTranslation("global");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassConditions, setShowPassConditions] = useState(false);
  const [validateConditions, setValidateConditions] = useState({
    length: "",
    lettler: "",
    capital: "",
    lower: "",
    number_symbol: "",
  });

  const handleValidations = (_password) => {
    let validations = { ...validateConditions };
    let regex_symbol = /^(?=.*[~!@#$%^&*()_+`\-={}[\]:";'<>?,./])/;
    let regex_number = /[0-9]/;

    validations.length = _password.length > 7;
    validations.lettler = _password.match(/[A-z]/);
    validations.capital = _password.match(/[A-ZÁÉÍÓÚÜÑ]/);
    validations.lower = _password.match(/[a-záéíóúüñ]/);
    validations.number_symbol =
      regex_symbol.test(_password) || regex_number.test(_password);

    setValidateConditions(validations);
    setShowPassConditions(
      !(
        validations.length &&
        validations.lettler &&
        validations.capital &&
        validations.lower &&
        validations.number_symbol
      )
    );
  };

  const handleChange = (event) => {
    setPassword(event.target.value);
    handleValidations(event.target.value);
    field.onChange(event);
  };

  return (
    <>
      {props.title && (
        <Typography
          style={{
            paddingBottom: "20px",
            fontSize: "14px",
          }}
        >
          {t(props.title)}
        </Typography>
      )}
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          {t(props.label)}
        </InputLabel>
        <OutlinedInput
          sx={{ borderRadius: "8px" }}
          id={props.name}
          type={showPassword ? "text" : "password"}
          value={password}
          data-testid="password-input"
          onChange={handleChange}
          error={
            props.errCustom
              ? props.errCustom.err ||
                (meta.touched && meta.error !== undefined)
              : meta.touched && meta.error !== undefined
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                data-testid="icon-btn"
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? (
                  <Visibility sx={{ color: "#404A56" }} />
                ) : (
                  <VisibilityOff sx={{ color: "#404A56" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          label={t(props.name)}
        />

        {props.created && showPassConditions && (
          <Box sx={{}}>
            <Card
              sx={{
                position: "absolute",
                borderRadius: "10px",
                width: "100%",
                zIndex: 2,
              }}
            >
              <CardContent>
                {props.conditions.map((condition, index) => (
                  <Typography key={index} style={{ fontSize: "12px" }}>
                    <CheckIcon
                      style={{
                        color: validateConditions[condition.type]
                          ? "blue"
                          : "gray",
                        marginRight: "5px",
                      }}
                    />
                    {t(condition.title)}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Box>
        )}
      </FormControl>
    </>
  );
};

export default Password;
