import React from "react";

import { Button } from "@mui/material";

import { useTranslation } from "react-i18next";

const Submit = ({ btn, submit }) => {
  const [t] = useTranslation("global");

  return (
    <Button
      color="primary"
      variant="contained"
      fullWidth
      type={btn.type}
      name={btn.name}
      onClick={submit}
      className={btn.styleClassName}
      style={btn.styles}
    >
      {t(btn.label)}
    </Button>
  );
};

export default Submit;
