import React, { useEffect, useState, Fragment } from "react";
import Iframe from "react-iframe";
import { useNavigate, useLocation } from "react-router-dom";
import { SnackBar } from "../components/commons/alert";
import OnBoardingLayout from "../components/on-boarding-layout";
import { Box, Container, Grid } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import GenericBackdrop from "../components/commons/GenericBackdrop";
import { useTranslation } from "react-i18next";

function FormScreen() {
  const i18n = useTranslation("global").i18n;
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const email = location.state.email;
  const password = location.state.password;
  let _url = `${process.env.REACT_APP_KYM_DOMAIN}/?form_id=F0009&country=${i18n.language}&email=${email}`;

  const styles = {
    contentView: {
      height: "100%",
      webkitBoxShadow: "1px 3px 1px #9E9E9E",
      mozBoxShadow: "1px 3px 1px #9E9E9E",
      boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
      borderRadius: "16px",
      backgroundColor: "white",
      margin: "0px 72px 0px 72px",
    },
  };

  const goToContract = () => {
    navigate("/MessageScreen", {
      screen: "MessageScreen",
      state: {
        type: {
          data: {
            image: "pending-validate",
            title: "¡Gracias por completar tu información!",
            subTitle:
              "Nuestro equipo validará tu registro y te notificaremos por correo electrónico una vez que se complete el proceso.",
          },
        },
      },
    });
  };

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.origin === process.env.REACT_APP_KYM_DOMAIN) {
        if (e.data.message === "SUCCESS" && userId !== "") {
          goToContract();
        } else if (e.data.message === "CREATE") {
          setLoader(true);
          e.data.data["password"] = password;
          e.data.data["email"] = email;
          axiosPrivate
            .post(`merchants`, e.data.data)
            .then((response) => {
              setUserId(response.data.id);
            })
            .catch((err) => {
              setLoader(false);
              SnackBar(err.response.data.error, "error");
            });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (userId !== "") {
      setLoader(false);
      goToContract();
    }
  }, [userId]);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Box sx={{ height: "75%", zIndex: 100, width: "100%" }} mt={12}>
        <Container
          sx={{ height: "100%", position: "absolute", paddingTop: "50px" }}
          maxWidth={false}
        >
          <Box sx={styles.contentView} p={5}>
            <Grid container>
              <Grid item xs={2} md={2}></Grid>
              <Grid item xs={12} md={8}>
                <Iframe
                  url={_url}
                  width="100%"
                  height="950px"
                  className="iFrame"
                />
              </Grid>
              <Grid item xs={2} md={2}></Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
}
export default function FormKym() {
  return <OnBoardingLayout screen={<FormScreen />} withoutContainer={true} />;
}
