import React from "react";
import Sesion from "../components/commons/sesion";
import Topics from "../components/help/topics";
import PermissionsGate from "../components/commons/PermissionsGate";

export default function Help() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Help"} />
      <PermissionsGate
        scopes={"resources.helps.actions.view"}
        foundRedirect={true}
      >
        <Topics />
      </PermissionsGate>
    </div>
  );
}
