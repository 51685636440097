import React, { Fragment, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Card, Typography, Button } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { Alert, Confirm } from "../commons/alert";
import { getClientId } from "../utils/jwt";
import GenericBackdrop from "../commons/GenericBackdrop";
import { simpleValidateExp } from "../../utils/validateExpression";
import Detailstores from "./detailstores";
import Stores from "./stores";
import TagManager from "react-gtm-module";

export default function CreateStores({ showCreateStore, type, data = "" }) {
  const { auth } = useAuth();
  const [errorStore, setErrorStore] = useState("");
  const [storeId, setStoreId] = useState("");
  const [storeName, setStoreName] = useState({
    value: data.name === undefined ? "" : data.name.trim(),
    error: false,
  });
  const [oldStoreName] = useState({
    value: data.name === undefined ? "" : data.name.trim(),
    error: false,
  });
  const [screen, setScreen] = useState("createStore");
  const [msisdn, setMsisdn] = useState("");
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);

  const expressions = {
    store: /^[a-zA-Z0-9-À-ÿ\s\_\.\,]{1,40}$/,
  };

  const handdlerStoreName = (event) => {
    simpleValidateExp(
      event.target.value.trim(),
      expressions.store,
      storeName,
      setStoreName
    );
  };

  const handdlerMsisdn = (event) => {
    let x = event.target.value;
    x = x.replace(/\D/g, "");

    if (x.length > 10) {
      event.target.value = x.slice(0, 10);
    } else {
      event.target.value = x;
    }
    setMsisdn(event.target.value);
  };

  useEffect(() => {
    if (storeName.error) {
      setErrorStore(t(`Stores.Errors.StoreName.letters`));
    } else {
      setErrorStore("");
    }
  }, [storeName]);

  const callCreateStore = (body) => {
    axiosPrivate
      .post(`stores`, body)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          if (response.data.message === "The store was created successfully") {
            setGTM("newBranchofficeCreated");
            setStoreId(response.data.storeId);
            setScreen("detailStore");
            Alert(t("Stores.SuccessText"), "success");
          } else {
            setGTM("newBranchofficeReactivated");
            showCreateStore();
            Alert(t("Stores.SuccessTextReactivated"), "success");
          }
        } else if (response.status === 204) {
          setLoader(false);
          Alert(t("Stores.IfExists"), "warning");
        }
      })
      .catch((_e) => {
        setLoader(false);
        Alert(t("Stores.Error"), "error");
      });
  };

  const callEditStore = (body) => {
    axiosPrivate
      .patch(`store`, body)
      .then((response) => {
        if (response.status === 204) {
          Alert(t("Stores.IfExists"), "warning");
          setLoader(false);
        } else {
          showCreateStore();
          setLoader(false);
          setGTM("branchofficeEdited");
          Alert(t("Stores.SuccessEditText"), "success");
        }
      })
      .catch((_e) => {
        setLoader(false);
        Alert(t("Stores.ErrorEdit"), "error");
      });
  };

  const selectMethodStore = () => {
    setLoader(true);
    if (storeName.error) {
      setLoader(false);
      Alert(t("Stores.Message.ErrorValue"), "warning");
    } else if (
      storeName.value !== "" &&
      storeName.value !== null &&
      storeName.value !== undefined
    ) {
      if (type === "create") {
        let body = {
          store_name: storeName.value,
          client_id: getClientId(auth),
          new_msisdn: msisdn,
          document_type: "TDI",
          birth_date: "1990-12-01",
          metadata_parent_id: getClientId(auth),
          metadata_status_id: "100",
          metadata_owner_name: storeName.value,
          metadata_trade_name: storeName.value,
          metadata_country: auth.country,
          metadata_email: auth.email,
          metadata_agent_accounting_group_id: "5",
          metadata_category_of_adjustment: "3",
          metadata_agent_group_id: 1,
        };
        callCreateStore(body);
      }
      if (type === "edit") {
        let body = {
          name: storeName.value,
          id: data.store_id,
        };
        callEditStore(body);
      }
    } else {
      setLoader(false);
      Alert(t("Stores.Message.ErrorFields"), "warning");
    }
  };

  const setGTM = (event) => {
    let tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM,
      dataLayer: {
        merchant_name: auth.businessName,
        merchant_id: auth.userId,
        event: event,
      },
    };
    TagManager.initialize(tagManagerArgs);
  };

  const titleSelector = () => {
    if (type === "create") return t("Stores.Addnewstore");
    if (type === "edit") return t("Stores.EditStore");
  };

  const backView = () => {
    setScreen("stores");
  };

  const screenCreateStore = () => {
    if (screen === "createStore") {
      return (
        <Fragment>
          <GenericBackdrop open={loader} />
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              paddingBottom: "24px",
            }}
          >
            <div style={{ width: "390px", textAlign: "initial" }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  paddingTop: "32px",
                  marginLeft: "24px",
                  fontWeight: 700,
                  fontSize: "18px",
                  color: "#404A56",
                }}
              >
                {titleSelector()}
              </Typography>
              <TextField
                id="name_store"
                defaultValue={data.name}
                label={t("Stores.Name")}
                onChange={handdlerStoreName}
                helperText={errorStore}
                error={storeName.error}
                fullWidth
                margin="normal"
                size="small"
                style={{
                  width: "381px",
                  borderRadius: "8px",
                  marginLeft: "24px",
                }}
                InputLabelProps={{
                  style: { color: "#00377D", fontSize: "14px" },
                }}
                inputProps={{
                  maxLength: 40,
                  style: {
                    fontSize: "14px",
                    color: "#404A56",
                    fontWeight: 400,
                  },
                }}
              />
              <TextField
                id="msisdn_store"
                label={t("Stores.Msisdn")}
                onChange={handdlerMsisdn}
                fullWidth
                margin="normal"
                size="small"
                style={{
                  width: "381px",
                  borderRadius: "8px",
                  marginLeft: "24px",
                  display: "none",
                }}
                InputLabelProps={{
                  style: { color: "#00377D", fontSize: "14px" },
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "#404A56",
                    fontWeight: 400,
                  },
                }}
              />
            </div>
          </Card>
          <div
            style={{
              float: "right",
              marginBlockEnd: "30px",
              marginTop: "30px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                background: "white",
                color: "#404A56",
                textTransform: "none",
                minWidth: "180px",
                borderColor: "#FFC10E",
                height: "48px",
              }}
              onClick={() => showCreateStore()}
              className="action-button-outlined"
            >
              {t("Buttons.Cancel")}
            </Button>
            <Button
              style={{
                background: "#FFC10E",
                color: "#404A56",
                marginLeft: "10px",
                textTransform: "none",
                minWidth: "180px",
                height: "48px",
              }}
              onClick={async () => {
                let isConfirm =
                  type === "edit"
                    ? await Confirm(
                        "",
                        t("Stores.MessageEdit", {
                          oldStore: oldStoreName.value,
                          store: storeName.value,
                        }),
                        "warning"
                      )
                    : true;
                if (isConfirm) {
                  selectMethodStore();
                }
              }}
              className="action-button"
            >
              {t("Buttons.Save")}
            </Button>
          </div>
        </Fragment>
      );
    } else if (screen === "detailStore") {
      return (
        <Fragment>
          <Detailstores data={{ key: storeId.toString() }} back={backView} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Stores />
        </Fragment>
      );
    }
  };

  return <div className="App">{screenCreateStore()}</div>;
}
