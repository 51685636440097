import React from "react";
import Box from "@mui/material/Box";
import TabPanel from "../../utils/tabPanel";
import Link from "@mui/material/Link";

const BodyCard = ({ title, subTitle, children, headerHandler }) => {
  return (
    <Box
      sx={{
        padding: "10px 15px",
        height: "fit-content",
        width: "100%",
      }}
    >
      <Link
        component="button"
        variant="body2"
        color="#004FB6"
        underline="none"
        data-testid={`header-line`}
        onClick={headerHandler}
        sx={{ fontWeight: 600 }}
      >
        {title} &nbsp;&nbsp; {">"} &nbsp;&nbsp;
      </Link>
      <Link
        component="button"
        variant="body2"
        color="#004FB6"
        underline="none"
        sx={{ fontWeight: 600 }}
      >
        {subTitle}
      </Link>
      <TabPanel value={1} index={1} style={{ margin: "40px 0" }}>
        {children}
      </TabPanel>
    </Box>
  );
};
export default BodyCard;
