import React, { useState, useEffect } from "react";
import dataJson from "./config/signUp-password.json";
import GenericForm from "./genericForm";
import { schema, getYup } from "./utils/yup";

export default function VerifyPassword(_props) {
  const [validates, setValidates] = useState({});

  useEffect(() => {
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  return (
    <GenericForm
      form={dataJson.forms}
      initialValue={{}}
      validates={getYup(validates)}
      onSubmit={(e) => _props.onSubmit(e)}
    />
  );
}
