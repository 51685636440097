import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({ title, data, getvalue, disabled }) {
  const [info, setgetinfo] = React.useState(
    data.length === 0 ? [] : Object.values(data[0])[0]
  );
  const [t] = useTranslation("global");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setgetinfo(value);
    getvalue(value);
  };

  return (
    <div>
      <FormControl
        sx={{ marginTop: "10px", width: "100%" }}
        disabled={disabled}
        size="small"
      >
        <InputLabel
          sx={{ color: "#404A56", fontSize: "14px" }}
          id="demo-multiple-name-label"
        >
          {t(title)}
        </InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={info}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          SelectDisplayProps={{
            style: { fontSize: "14px", textAlign: "initial" },
          }}
        >
          {data.map((row, index) => {
            return (
              <MenuItem key={index} value={Object.values(row)[0]}>
                {Object.values(row)[1]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
