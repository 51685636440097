import React, { useEffect, useState } from "react";
import MenuCard from "../components/commons/menu-card";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../themes/Theme";
import Sesion from "../components/commons/sesion";
import { Alert } from "../components/commons/alert";
import DataJson from "../components/config/payment-integration.json";
import Autentication from "../components/payment-integration/authentication";
import DocumentationLayaout from "../components/api-documentation-layaout/documentationLayaout";
import dataAuthentication from "../components/config/pi-authentication.json";
import MyApiKey from "../components/payment-integration/myApiKey";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

let contentsList = [
  //Agregar componente en el orden de figma o del DataJson
  <Autentication
    Title={dataAuthentication.Title}
    Text={dataAuthentication.TextContent}
    TopicTitle={dataAuthentication.TopicTitle}
    TextTopic={dataAuthentication.TextTopic}
  />,
  <MyApiKey />,
];

export default function PaymentIntegration() {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();

  const [contents, setContents] = useState(contentsList);
  const [apiDocumentationContents, setApiDocumentationContents] = useState([]);

  useEffect(() => {
    getSwaggerDocumentation();
  }, [apiDocumentationContents]);

  const getSwaggerDocumentation = () => {
    axiosPrivate
      .get(`integrations/documentation`)
      .then((response) => {
        setApiDocumentationContents(response.data);
        buildContentsAndTabs();
      })
      .catch((_) => {
        Alert(t("General.Message.ErrorService"), "error");
      });
  };

  const buildContentsAndTabs = () => {
    apiDocumentationContents.forEach((documentation) => {
      contentsList.push(<DocumentationLayaout data={documentation} />);
      DataJson.sections.push({ name: documentation.title });
    });
    setContents(contentsList);
  };

  return (
    <div className={"Drawer"}>
      <Sesion screen={"PaymentIntegration"} />
      <ThemeProvider theme={Theme}>
        <MenuCard
          elements={DataJson.sections}
          title={DataJson.title}
          name={DataJson.name}
          contents={contents}
        />
      </ThemeProvider>
    </div>
  );
}
