/**
 * Software distributed under the Apache License is distributed on an "AS IS" basis,
 * WITHOUT WARRANTY OF ANY KIND, either express or implied. See the License for the
 * specific language governing rights and limitations under the License.
 */

import React from "react";
import PropTypes from "prop-types";

import "./marks.css";

const PREDEFINED_SIZE_MAP = {
  small: "16px",
  medium: "24px",
  large: "52px",
  xLarge: "72px",
  xxLarge: "96px",
};

const ICON_COLOR = {
  success: "#66BC6B",
  error: "#D95A3A",
  warning: "#E29836",
};

export function Marks({ size, icon }) {
  const computedSize = PREDEFINED_SIZE_MAP[size] || size;
  const style = {
    width: computedSize,
    height: computedSize,
    backgroundColor: "white",
  };
  style["--mark-fill-color"] = ICON_COLOR[icon];

  switch (icon) {
    case "success":
      return (
        <svg
          className="mark"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
          viewBox="0 0 52 52"
        >
          <circle className="mark__circle" cx="26" cy="26" r="25" fill="none" />
          <path
            className="mark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      );
    case "error":
      return (
        <svg
          className="mark"
          style={style}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle className="mark__circle" cx="26" cy="26" r="25" fill="none" />
          <path
            className="mark__check"
            d="M39.2 16.8L16.8 39.2001"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="mark__check"
            d="M16.8 16.8L39.2 39.2001"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "warning":
      return (
        <svg
          className="mark"
          style={style}
          viewBox="0 0 56 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle className="mark__circle" cx="26" cy="26" r="25" fill="none" />
          <path
            className="mark__check"
            d="M27.9996 17.2998V28.4998"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="mark__check"
            d="M27.9996 39.7002H28.0276"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}

Marks.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

Marks.defaultProps = {
  size: "large",
};
