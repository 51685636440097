import React from "react";
import { Card, CardContent, Button } from "@mui/material";
import QRcode from "qrcode.react";
import { useTranslation } from "react-i18next";
import tigomoney from "../../assets/logoTigo/togosvg2.svg";
import { encodeJWT } from "../../components/utils/jwt";

function QrGenerate(props) {
  const [t] = useTranslation("global");

  const downloadQR = () => {
    const canvas = document.getElementById("myqr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "myqr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  let url = props.url;
  let data = Object.assign({}, props);
  delete data.url;
  const dataJWT = encodeJWT(data);
  const tigo_url = `${url}?data=${dataJWT}&v=2`;

  let fontSize = {
    fontSize: "25px",
  };

  let imageLogo = {
    marginLeft: "15px",
  };

  let buttonText = {
    textTransform: "none",
    marginBottom: "20px",
    textAlign: "center",
  };

  let alignCenter = {
    textAlign: "center",
  };

  let container = {
    margin: "0px auto",
    width: "fit-content",
    textAlign: "left",
  };

  let child = {
    margin: "0px auto",
    border: "solid 5px",
    width: "fit-content",
    padding: "15px",
    borderRadius: "20px",
  };

  return (
    <Card
      style={{
        borderRadius: "16px",
        boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
      }}
    >
      <CardContent>
        <div style={container}>
          <h2 style={buttonText}>{t("QrGenerate.CodeQR")}</h2>

          <div id="newQR" style={child}>
            <QRcode id="myqr" value={tigo_url} size={211} />

            <img
              id="logoTigo"
              style={imageLogo}
              height={211}
              src={tigomoney}
              alt="logo"
            />
          </div>
          <h3 style={{ color: "Gray" }}>
            <label style={fontSize}>{props.name}</label>
            <br></br>
            {"ID: " + props.idSucursal}
          </h3>

          <div style={alignCenter}>
            <Button
              onClick={downloadQR}
              style={buttonText}
              variant="contained"
              className="action-button"
            >
              {" "}
              {t("QrGenerate.Download")}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default QrGenerate;
