import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const getNavigate = (auth, location, allowedRoles) => {
  if (auth?.roleName?.find((role) => allowedRoles?.includes(role)))
    return <Outlet />;

  if (auth?.authToken)
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;

  return <Navigate to="/SignIn" state={{ from: location }} replace />;
};

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  return getNavigate(auth, location, allowedRoles);
};

export default RequireAuth;
