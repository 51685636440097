import React from "react";
import Insights from "../components/insights/insights";
import Sesion from "../components/commons/sesion";
import PermissionsGate from "../components/commons/PermissionsGate";

export default function Insight() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Insights"} />
      <PermissionsGate
        scopes={"resources.insights.actions.view"}
        foundRedirect={true}
      >
        <Insights />
      </PermissionsGate>
    </div>
  );
}
