import React from "react";
import TransferDetail from "../components/insights/transfersDetail";
import { useLocation } from "react-router-dom";
import Sesion from "../components/commons/sesion";

export default function TransfersDetail() {
  const location = useLocation();
  const type = location.state.type;

  return (
    <div className={"Drawer"}>
      <Sesion screen={"Insights"} />
      <TransferDetail type={type} />
    </div>
  );
}
