import React, { useState, useEffect } from "react";
import dataJson from "./config/signUp-email.json";
import GenericForm from "./genericForm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import { schema, getYup } from "./utils/yup";
import { SnackBar } from "./commons/alert";

export default function VerifyEmail(_props) {
  const [t] = useTranslation("global");
  const [data, setData] = useState(dataJson.forms);
  const [validates, setValidates] = useState({});
  const axiosPrivate = useAxiosPrivate();

  const handleSubmit = (values) => {
    let newData = { ...data };

    axiosPrivate
      .get(`merchants/emails`, {
        params: {
          email: values["email"],
        },
      })
      .then((response) => {
        if (response.data) {
          newData.fields[0].props.conditions[0].created = true;
          setData(newData);
        } else {
          newData.fields[0].props.conditions[0].created = false;
          _props.onSubmit(values);
        }
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  };

  useEffect(() => {
    let _validates = {};
    data.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  return (
    <GenericForm
      form={data}
      initialValue={{}}
      validates={getYup(validates)}
      onSubmit={handleSubmit}
    />
  );
}
