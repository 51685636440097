import { Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PathImage from "../assets/generic/RoundHead.svg";
import PermissionsGate from "../components/commons/PermissionsGate";
import { getCountry } from "../components/utils/getCountry";
import GenericPrivacyNotices from "../translations/privacyNotices";

function PrivacyScreen() {
  const [country, setCountry] = useState("");
  const [t] = useTranslation("global");

  useEffect(() => {
    const countryValue = getCountry();
    setCountry(countryValue);
  });

  const styles = {
    scroll: {
      "&::-webkit-scrollbar": {
        width: 11,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FFFFFF",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C4C4C4",
        borderRadius: 10,
      },
    },
    title: {
      textAlign: "center",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      color: "#00377D",
    },
  };

  return (
    <Box
      sx={styles.scroll}
      className="privacy-card"
      mt={12}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
    >
      <Typography variant="h3" component="h3" sx={styles.title}>
        {t("Privacy.Title")}
      </Typography>
      <Box mt={8} style={{ width: "80%" }}>
        <GenericPrivacyNotices country={country} />
      </Box>
    </Box>
  );
}

export default function Privacy() {
  const [t] = useTranslation("global");
  return (
    <div>
      {/* Header */}
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="space-between"
        className="head-privacy-container"
      >
        <Grid item xl={3} xs={6} display="flex" justifyContent="center">
          <img
            src="https://www.worldremit.com/images/logos/Tigo-logo.png"
            alt="tigo money"
            className="img-tigo-privacy"
          />
        </Grid>

        <Grid
          item
          xl={9}
          xs={2}
          display="flex"
          justifyContent="end"
          className="privacy-logo"
        >
          <img
            style={{
              position: "absolute",
              height: "60%",
            }}
            src={PathImage}
          />
        </Grid>
      </Grid>

      {/* Body */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <PrivacyScreen />
      </Grid>

      {/* Footer */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        className="footer-privacy-container "
        m={"auto"}
      >
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="left"
          textAlign="left"
        >
          <Typography style={{ color: "#FFF" }}>
            {t("General.Message.Rights")}
          </Typography>
        </Grid>

        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="space-evenly"
        ></Grid>
      </Grid>
    </div>
  );
}
