import React from "react";
import OnBoardingLayout from "../components/on-boarding-layout";
import { useLocation } from "react-router-dom";
import GenericMessage from "../components/genericMessage";

export default function MessageScreen() {
  const location = useLocation();
  const type = location.state.type;

  return (
    <OnBoardingLayout
      screen={<GenericMessage type={type} />}
      withoutContainer
    />
  );
}
