import { React, useEffect, useState, Fragment } from "react";
import dataJson from "./config/forgot-password.json";
import GenericForm from "./genericForm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { schema, getYup } from "./utils/yup";
import { SnackBar } from "./../components/commons/alert";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GenericBackdrop from "./commons/GenericBackdrop";

export default function FormForgotPassword() {
  const [t] = useTranslation("global");
  const [data, setData] = useState(dataJson.forms);
  const [validates, setValidates] = useState({});
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setLoader(true);
    let newData = { ...data };

    axiosPrivate
      .patch(`users/password`, {
        email: values.email,
      })
      .then(() => {
        setLoader(false);
        newData.fields[0].props.legend = t(
          "ForgotPassword.Step1.FieldEmail.Props.Legend",
          { email: values.email }
        );
        newData.actionsButtons[0].label =
          "ForgotPassword.Step1.ActionsButtons.LabelReturnLogin";
        newData.fields[0].props.type = "";
        newData.footer.textlink = "";
        setData(newData);
        setStep(2);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.status === 404) {
          newData.fields[0].props.legenderror =
            "ForgotPassword.Step1.FieldEmail.Props.Legenderror";
          setData(newData);
        } else {
          SnackBar(t("General.Message.ErrorService"), "error");
        }
      });
  };

  const backToLogin = () => {
    navigate("/SignIn", {
      replace: true,
    });
  };

  useEffect(() => {
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <GenericForm
        form={dataJson.forms}
        initialValue={{}}
        validates={getYup(validates)}
        onSubmit={step == 1 ? handleSubmit : backToLogin}
      />
    </Fragment>
  );
}
