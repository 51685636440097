import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

const PermissionsGate = ({ children, foundRedirect = null, scopes = [] }) => {
  const { auth } = useAuth();

  let permissionGranted =
    auth?.roles && scopes.split(".").reduce((obj, i) => obj[i], auth?.roles);

  if (!permissionGranted && !foundRedirect) return <></>;

  if (!permissionGranted && foundRedirect) return <Navigate to="/Insight" />;

  return <>{children}</>;
};

export default PermissionsGate;
