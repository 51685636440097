import { Card, CardContent, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import GenericBackdrop from "../../components/commons/GenericBackdrop";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Alert } from "../commons/alert";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "16px",
    boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
  },
}));

export default function Reverse({ props, setComponentDetail, showPin }) {
  const [t] = useTranslation("global");
  const classes = useStyles();
  const [reason, setReason] = useState("");
  const [pin, setPin] = useState("");
  const [component, setComponent] = useState(true);
  const [error, setError] = useState(false);
  const [loaderAdjustment, setLoaderAdjustment] = useState(false);
  let navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleChangeReason = (event) => {
    if (event.target.value === "") {
      setReason("");
      setError(true);
    } else {
      setReason(event.target.value);
      setError(false);
    }
  };

  const handleChangePin = (event) => {
    setPin(event.target.value);
  };

  const adjustment = () => {
    setLoaderAdjustment(true);
    axiosPrivate
      .post(
        `transactions/adjustment?transaction_id=${props.type.transaction_id}`
      )
      .then((_response) => {
        setLoaderAdjustment(false);
        Alert(t("Insights.Transactions.SuccessText"), "success").then(() =>
          showInsights()
        );
      })
      .catch((err) => {
        setLoaderAdjustment(false);
        if (err["response"]["data"]["errorDescription"]) {
          Alert(err["response"]["data"]["errorDescription"], "error").then(() =>
            showInsights()
          );
        } else {
          Alert(t("Insights.Transactions.ErrorText"), "error").then(() =>
            showInsights()
          );
        }
      });
  };

  const showInsights = () => {
    navigate("/");
  };

  const getMessageError = () => {
    return error ? t(`Insights.Transactions.ReasonError`) : "";
  };

  const validate = () => {
    if (reason === "") {
      setError(true);
      getMessageError();
    } else {
      setComponent(false);
    }
  };

  return (
    <Fragment>
      <GenericBackdrop open={loaderAdjustment} />
      <Stack spacing={2}>
        <Card className={classes.card}>
          <CardContent>
            <Stack spacing={4} sx={{ width: "30%", borderRadius: "16px" }}>
              <Typography
                className="text"
                style={{
                  display: "inline",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#404A56",
                }}
              >
                {component
                  ? t(`Insights.Transactions.Adjustment`)
                  : t(`Insights.Transactions.AdjustmentConfirm`)}
              </Typography>

              <Card
                style={{
                  backgroundColor: "rgba(239, 241, 243, 0.3)",
                  border: "1px solid #EFF1F3",
                  borderRadius: "16px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Typography
                      className="text"
                      style={{
                        display: "inline",
                        fontSize: "14px",
                        height: "7px",
                        color: "#708195",
                      }}
                    >
                      {component
                        ? t(`Insights.Transactions.AmountRefundAvailable`)
                        : t(`Insights.Transactions.AmountRefund`)}
                    </Typography>

                    <Typography
                      className="text"
                      style={{
                        display: "inline",
                        fontSize: "28px",
                        fontWeight: "500",
                        lineHeight: "40px",
                        color: "#404A56",
                        height: "45px",
                      }}
                    >
                      {props.type.amount}
                    </Typography>
                    {!component && (
                      <>
                        <Typography
                          className="text"
                          style={{
                            display: "inline",
                            fontSize: "14px",
                            color: "#708195",
                            height: "8px",
                          }}
                        >
                          {t(`Insights.Transactions.AdjustmentType`)}
                        </Typography>
                        <Typography
                          className="text"
                          style={{
                            display: "inline",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#404A56",
                          }}
                        >
                          {"Total"}
                        </Typography>
                        <Typography
                          className="text"
                          style={{
                            display: "inline",
                            fontSize: "14px",
                            height: "9px",
                            color: "#708195",
                          }}
                        >
                          {t(`Insights.Transactions.Description`)}
                        </Typography>
                        <Typography
                          className="text"
                          style={{
                            display: "inline",
                            fontSize: "16px",
                            color: "#404A56",
                            fontWeight: "500",
                            textAlign: "justify",
                          }}
                        >
                          {reason}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </CardContent>
              </Card>
              {component ? (
                <TextField
                  error={error}
                  helperText={getMessageError()}
                  id="adjustment_reason"
                  label={t(`Insights.Transactions.AdjustmentReason`)}
                  multiline
                  maxRows={4}
                  value={reason}
                  inputProps={{
                    maxLength: 250,
                  }}
                  onChange={handleChangeReason}
                />
              ) : (
                <>
                  {showPin && (
                    <div>
                      <Typography
                        className="text"
                        style={{
                          display: "inline",
                          fontSize: "14px",
                          color: "#404A56",
                        }}
                      >
                        {t(`Insights.Transactions.InfoPin`)}
                      </Typography>
                      <TextField
                        type="number"
                        id="adjustment_pin"
                        label={"PIN"}
                        multiline
                        maxRows={4}
                        value={pin}
                        onChange={handleChangePin}
                      />
                    </div>
                  )}
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Button
            variant="outlined"
            onClick={() => setComponentDetail(true)}
            style={{
              background: "white",
              color: "#404A56",
              textTransform: "none",
              minWidth: "90px",
              maxWidth: "102px",
              borderColor: "#FFC10E",
              height: "40px",
            }}
            className="action-button-outlined"
          >
            {t(`Buttons.Cancel`)}
          </Button>

          {component ? (
            <Button
              onClick={() => validate()}
              style={{
                background: "#FFC10E",
                color: "#404A56",
                marginLeft: "10px",
                textTransform: "none",
                minWidth: "90px",
                height: "40px",
                width: "118px",
              }}
              className="action-button"
            >
              {t(`Buttons.Continue`)}
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={() => setComponent(true)}
                style={{
                  background: "white",
                  color: "#404A56",
                  textTransform: "none",
                  minWidth: "90px",
                  maxWidth: "102px",
                  borderColor: "#FFC10E",
                  height: "40px",
                  fontSize: "16px",
                }}
                className="action-button-outlined"
              >
                {t(`Buttons.Edit`)}
              </Button>
              <Button
                data-testid="buttonAdjustment"
                onClick={() => adjustment()}
                style={{
                  background: "#FFC10E",
                  color: "#404A56",
                  marginLeft: "10px",
                  textTransform: "none",
                  minWidth: "90px",
                  maxWidth: "134px",
                  height: "40px",
                  fontSize: "16px",
                }}
                className="action-button"
              >
                {t(`Insights.Transactions.Adjust`)}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Fragment>
  );
}
