import React, { useEffect, useState, Fragment } from "react";
import BasicTable from "../commons/basicTable";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Grid from "@mui/material/Grid";
import TableCell from "@mui/material/TableCell";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { Card, Typography, Button } from "@mui/material";
import CreateStores from "./createStores";
import Editusers from "../users/editusers";
import { Alert } from "../commons/alert";
import ActionDeleteUser from "../users/actionDeleteUser";
import ActionEditUser from "../users/actionEditUser";

const Detailstores = ({ data, back }) => {
  const [store, setStore] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataRow, setDataRow] = useState([]);
  const [t] = useTranslation("global");
  const [enable, setenable] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const columns = [
    "Stores.TableUsers.Name",
    "Stores.TableUsers.Email",
    "Stores.TableUsers.Permissions",
    "Stores.TableUsers.Action",
  ];
  const condition = [
    "user_id",
    "store_id",
    "role_id",
    "names",
    "first_name",
    "second_name",
    "store",
  ];
  const [screen, setScreen] = useState("detailStores");

  function getUsers() {
    const url = "users/";
    axiosPrivate
      .get(url, { params: { store_id: data.key } })
      .then((response) => {
        setDataRow(response.data);
      })
      .catch((_err) => {
        Alert(t("General.Message.ErrorService"), "error");
      });
  }

  const getStore = () => {
    axiosPrivate
      .get(`stores`, { params: { store_id: data.key } })
      .then((response) => {
        if (response.data.data.length !== 0) {
          let responseDdata = {};
          response.data.data.map((element) => {
            if (element.key === data.key) responseDdata = element;
          });
          setenable(false);
          setStore(responseDdata);
        }
      })
      .catch((_err) => {
        Alert(t("General.Message.ErrorService"), "error");
      });
  };

  useEffect(() => {
    getStore();
    getUsers();
  }, []);

  const editUserActivate = (row, screenView) => {
    setDataUser(row);
    setScreen(screenView);
  };

  const editStoreActivate = (row, screenView) => {
    let editRow = row;
    editRow.name = editRow.name.split("|", 1)[0].trim();
    setScreen(screenView);
    setDataUser(editRow);
  };

  const handleDeleteUser = (user_id) => {
    axiosPrivate
      .delete("users", {
        params: { user_id: user_id },
      })
      .then((response) => {
        if (response.status === 204 || response.data === "User deleted.") {
          return Alert(t("Users.Delete.Deleted"), "success");
        }
      })
      .catch((err) => {
        if (
          err.response.status === 404 ||
          err.response.data === "User already deleted."
        )
          return Alert(t("Users.Delete.AlreadyDeleted"), "warning");
        else if (
          err.response.status === 410 ||
          err.response.data === "User not exist."
        )
          return Alert(t("Users.Delete.NotExist"), "warning");
      });
  };

  const createaction = (row) => {
    return (
      <Fragment key={uuidv4()}>
        <TableCell
          align="right"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActionDeleteUser row={row} handleDeleteUser={handleDeleteUser} />
          <ActionEditUser
            row={row}
            editUserActivate={editUserActivate}
            type={"editUser"}
          />
        </TableCell>
      </Fragment>
    );
  };

  const backDetails = () => {
    getUsers();
    setScreen("detailStores");
  };

  const screenEdit = () => {
    if (screen === "editStore") {
      return (
        <CreateStores
          showCreateStore={backDetails}
          type={"edit"}
          data={dataUser}
        />
      );
    } else if (screen === "editUser") {
      return (
        <div style={{ marginBottom: "20px" }}>
          <Editusers type={"edit"} data={dataUser} activate={backDetails} />{" "}
        </div>
      );
    } else {
      return (
        <Fragment>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              paddingBottom: "20px",
              fontWeight: 700,
              fontSize: "18px",
              color: "#404A56",
              textAlign: "initial",
            }}
          >
            {t("Stores.DetailStore")}
          </Typography>
          <Grid style={{ paddingTop: "10px" }} container direction="column">
            <Grid style={{ paddingBottom: "50px" }} item xs={6}>
              <Card
                style={{
                  textAlign: "initial",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
                }}
              >
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <Grid container direction="column">
                      <Typography
                        variant="h5"
                        gutterBottom
                        component="div"
                        sx={{
                          paddingTop: "20px",
                          marginLeft: "24px",
                          fontWeight: 700,
                          fontSize: "24px",
                          color: "#404A56",
                        }}
                      >
                        {store.name}
                      </Typography>
                      <Typography
                        variant="subTitle18_700"
                        gutterBottom
                        component="div"
                        sx={{
                          paddingTop: "5px",
                          paddingBottom: "10px",
                          marginLeft: "24px",
                        }}
                      >
                        {store.key}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "right",
                      paddingRight: "20px",
                      paddingTop: "35px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={
                        !enable
                          ? {
                              background: "white",
                              color: "#004FB6",
                              textTransform: "none",
                              minWidth: "93px",
                              borderColor: "#FFC10E",
                              height: "40px",
                            }
                          : null
                      }
                      disabled={enable}
                      data-testid="EditStore"
                      onClick={() => editStoreActivate(store, "editStore")}
                    >
                      {t("Buttons.Edit")}
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <BasicTable
                dataTable={dataRow}
                dataColumns={columns}
                action={createaction}
                condition={condition}
                align="left"
              />
            </Grid>
          </Grid>
          <div
            style={{
              textAlign: "right",
              marginBlockEnd: "30px",
              marginTop: "30px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                background: "white",
                color: "#404A56",
                textTransform: "none",
                minWidth: "139px",
                borderColor: "#FFC10E",
                height: "48px",
              }}
              onClick={() => back(store, "store")}
              className="action-button-outlined"
            >
              {t("Buttons.Back")}
            </Button>
          </div>
        </Fragment>
      );
    }
  };

  return <div className="App">{screenEdit()}</div>;
};

export default Detailstores;
