import { useTranslation } from "react-i18next";
import { Box, Container, Typography, Button, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FinishProcess from "../../src/assets/generic/FinishProcess.svg";
import PendingValidate from "../../src/assets/generic/PendingValidate.svg";
import RejectedProcess from "../../src/assets/generic/RejectedProcess.svg";
import TagManager from "react-gtm-module";
import useAuth from "../hooks/useAuth";

const Data = {
  "finish-contract": FinishProcess,
  rejected: RejectedProcess,
  "pending-validate": PendingValidate,
};
const Functions = {
  link: Link.onClick,
};
const Message = ({ data }) => {
  const { auth } = useAuth();
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  let path;
  switch (data.data.image) {
    case "finish-contract":
      path = "/succeededApplication";
      break;
    case "pending-validate":
      path = "/validationProcess";
      break;
    case "rejected":
      path = "/deniedApplication";
      break;
  }
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    dataLayer: {
      merchant_name: auth.businessName,
      merchant_id: auth.userId,
      event: path,
    },
  };
  TagManager.initialize(tagManagerArgs);

  const styles = {
    button: {
      backgroundColor: "#fabe00",
      color: "#404A56",
    },
    contentView: {
      height: "100%",
      webkitBoxShadow: "1px 3px 1px #9E9E9E",
      mozBoxShadow: "1px 3px 1px #9E9E9E",
    },
    contentImage: {
      height: "30vh",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  };

  return (
    <Box
      sx={{ height: "90%", zIndex: 0, width: "100%", position: "absolute" }}
      mt={12}
    >
      <Container sx={{ height: "100%" }} maxWidth={false}>
        <Box sx={styles.contentView} p={5}>
          <Box
            sx={{
              boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              borderRadius: "16px",
            }}
          >
            <Box
              mt={3}
              mb={1}
              sx={{
                justifyContent: "center",
                display: "flex",
                background: "white",
                borderRadius: "16px",
              }}
            >
              <Typography
                variant="title"
                component="div"
                gutterBottom
                style={{ marginTop: "20px", fontSize: "36px" }}
              >
                {t(data.data.title)}
              </Typography>
            </Box>
            <Box mb={5} sx={{ justifyContent: "center", display: "flex" }}>
              <Typography variant="subTitle" component="div" gutterBottom>
                {t(data.data.subTitle)}
              </Typography>
            </Box>
            <Box mb={3} sx={{ justifyContent: "center", display: "flex" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box sx={styles.contentImage}>
                    <img src={Data[data.data.image]} alt="Finish"></img>
                  </Box>
                </Grid>
                {/* <Grid container justifyContent="center">
                  <Grid item xs={6} sx={styles.contentImage}></Grid> */}
              </Grid>
            </Box>
            <Box mt={13} sx={{ justifyContent: "center", display: "flex" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  lg={1}
                  mb={2}
                  sx={{ textAlign: "center" }}
                >
                  {data.data.button && (
                    <Button
                      style={styles.button}
                      onClick={
                        data.data.button.onClick
                          ? () => Functions[data.data.button.onClick]
                          : () => navigate(data.data.button.link)
                      }
                    >
                      {t(data.data.button.name)}
                    </Button>
                  )}
                  {data.data.loading && (
                    <CircularProgress
                      style={{
                        marginLeft: "20px",
                        width: "60px",
                        height: "60px",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const GenericMessage = (props) => {
  const DataMessages = props.type;
  if (!DataMessages) {
    return null;
  }

  return <Message data={DataMessages} />;
};

export default GenericMessage;
